import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useAuth } from "../../../connections/net";
import "./LiveTile.css";
import { Tile } from "./Tile";
import { UserDropdown } from "../../layout/UserDropdown";
import $ from "jquery";
import { messageWrapper as t } from "../../../utils/message-wrapper";
import { IrespModal } from "../../common/IrespModal";
import { EspDropdown } from "../../common/EspDropdown";

import AlarmTypeImg from "../../../images/oac/OAC_Alarms_Noti-1A-ababab_png_1.png";
import { SideBar } from "../../layout/SideBar";
import { NurseCall } from "../nursecall/NurseCall";
import { Intercom } from "../intercom/Intercomm";

import MicDisableImage from "../../../images/mic2_disable.png";
import MicEnableImage from "../../../images/mic2_enable.png";
import MicMute from "../../../images/mic2_disable.png";
import IntercomBlack from "../../../images/intercom_black.png";
import IntercomRed from "../../../images/intercom_red.png";
import IntercomWhite from "../../../images/intercom_white.png";

export function LiveTile(props) {
  useAuth();

  const [isIntercomOpen, setIntercomOpen] = useState(false); // State to control Intercom popup
  // const [intercomInfo, setIntercomInfo] = useState(null); // State to store props.info for Intercom
  const intervalRef = useRef(null); // Ref to hold the interval ID
  const intervalDiscRef = useRef(null);
  const [intercomImage, setIntercomImage] = useState(IntercomBlack);
  const user = localStorage.getItem("ADMIN_OMNI2_USER");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupInfo, setPopupInfo] = useState(null);
  const [isForceClose, setForceClose] = useState(false);
  const [isCallStart, setCallStart] = useState(false);
  const [createRoom, setCreateRoom] = useState(null); // Initially null or a default value

  const isPopupOpenRef = useRef(isPopupOpen);
  const popupInfoRef = useRef(null);
  const nurseCallRef = useRef(null);
  const previousDisconnectedValueRef = useRef(
    localStorage.getItem("OMNI2_DISCONNECTED") === "true"
  );

  const [state, setState] = useState({
    type: "1",
    isOpenDismissModal: false,
    isAgree: true,
    ntiles: localStorage.getItem(`${user}_OAC_LT_ITEMS_PER_ROW`) || 8,
    selectedTone: Number(localStorage.getItem(`${user}_OAC_LT_TONE`) || 1),
    usedTone: Number(localStorage.getItem(`${user}_OAC_LT_TONE`) || 1),
  });

  // Check the disconnected status in localStorage every second
  useEffect(() => {
    console.log("forceClose ..........");
    const checkDisconnectedStatus = () => {
      console.log("forceClose checkDisconnectedStatus");
      // Get the current value from localStorage and ensure it's boolean
      const currentValue =
        localStorage.getItem("OMNI2_DISCONNECTED") === "true";

      const previousValue = previousDisconnectedValueRef.current;

      console.log(
        "................forceClose currentValue: " +
          currentValue +
          ", previousValue: " +
          previousValue
      );

      if (!previousValue && currentValue) {
        console.log(
          "OMNI2_DISCONNECTED changed from null/false to true. Triggering logic."
        );
        console.log("forceClose now : true");
        setForceClose(true);
      } else {
        setForceClose(false); // Reset it once
      }

      // // Handle resetting of force close
      // if (isForceClose) {
      //   console.log(".currentValue.......RESET");
      //   console.log("forceClose now : false");

      // }
      // Update the ref to hold the current value as the previous one
      previousDisconnectedValueRef.current = currentValue;
    };
    console.log("forceClose ..........1");
    // Set an interval to check every second
    intervalDiscRef.current = setInterval(checkDisconnectedStatus, 1000);
    console.log("forceClose ..........2");
    // Clean up the interval on component unmount
    return () => {
      if (intervalDiscRef.current) {
        clearInterval(intervalDiscRef.current);
      }
    };
  }, []); // Dependency only on isForceClose, since the interval is set once and checked via refs

  useEffect(() => {
    const { intercom } = props.info || {};

    // If requester is not null, start alternating between IntercomBlack and IntercomRed
    if (intercom?.requester) {
      intervalRef.current = setInterval(() => {
        setIntercomImage((prevImage) =>
          prevImage === IntercomBlack ? IntercomRed : IntercomBlack
        );
      }, 1000); // Alternate every second
    } else {
      clearInterval(intervalRef.current);
      setIntercomImage(IntercomBlack); // Reset to IntercomBlack
    }

    // Clean up the interval on unmount or when the requester is null
    return () => {};
  }, [props.info.intercom?.requester]);

  useEffect(() => {
    console.log("---- info", JSON.stringify(props.info.intercom));

    // Check if props.info.intercom has an id property
    if (props.info?.intercom?.id) {
      // Try to get the previously saved roomId from localStorage
      const savedRoomId = localStorage.getItem("savedRoomId");

      // console.log("savedRoomId : " + savedRoomId);
      // Print props.info.intercom for debugging purposes
      // console.log("Intercom Info:", props.info.intercom);

      // Compare the savedRoomId with the current intercom id
      if (savedRoomId !== props.info.intercom.id) {
        // Save the new room_id to localStorage
        localStorage.setItem("savedRoomId", props.info.intercom.id);

        // Call openIntercom if the ids are different
        openIntercom();
      }
    }
  }, [props.info]);

  useEffect(() => {
    isPopupOpenRef.current = isPopupOpen;
  }, [isPopupOpen]);

  useEffect(() => {
    const closeLivetile = function (e) {
      // localStorage.setItem("OMNI2_TILES_OPENING", "false");
      localStorage.setItem("OMNI2_ALERT", "false");
    };
    window.addEventListener("beforeunload", closeLivetile);

    return () => {
      // localStorage.setItem("OMNI2_TILES_OPENING", "false");
      window.removeEventListener("beforeunload", closeLivetile);
    };
  }, []);

  let soundInterval;

  useEffect(() => {
    console.log(
      "props.info?.intercom?.requester : " +
        props.info?.intercom?.requester +
        " , " +
        props.hasSound
    );
    if (props.hasSound && props.info?.intercom?.requester == undefined) {
      playSound();
      // navigator.vibrate(2000);
      soundInterval = setInterval(() => {
        playSound();
        // navigator.vibrate(2000);
      }, [5000]);
    } else {
      clearInterval(soundInterval);
      stopSound();
      // navigator.vibrate(0);
    }

    return () => {
      clearInterval(soundInterval);
    };
  }, [props.hasSound, state.usedTone, props.info]);

  // useEffect(() => {
  //   console.log(props.info);

  //   console.log(
  //     sessionStorage.getItem(`${sessionStorage.getItem("OMNI2_ROLE")}_token`)
  //   );
  //   const apiUrl = "https://api.oac-system.com:8443";
  // }, [props.info]);

  const openIntercom = () => {
    if (props.info?.intercom?.requester) {
      setCreateRoom(false);
    } else {
      setCreateRoom(true);
    }
    setCallStart(true);
    setIntercomOpen(true); // Open the Intercom popup
  };

  const closeIntercom = () => {
    setCallStart(false);
    if (props.info?.intercom?.requester) setIntercomOpen(false); // Close the Intercom popup
  };

  const openNurseCall = (info) => {
    clearNurseCall(info.bcu_wifi_mac);
    popupInfoRef.current = info;
    setPopupOpen(true);
  };

  // const Popup = () =>
  //   isPopupOpen && popupInfo ? (
  //     <div className={"iresp-modal"}>
  //       <NurseCall info={popupInfo} handleClosePopup={handleClosePopup} />
  //     </div>
  //   ) : null;

  const handleClosePopup = (info) => {
    console.log("...currentValue......3");
    setPopupOpen(false); // Assuming you're using hooks to manage state
    console.log(popupInfoRef.current.bcu_wifi_mac);

    disconnectNurseCall(popupInfoRef.current.bcu_wifi_mac);
    console.log("...currentValue......4");
  };

  const handleTurnOffSound = (info) => {
    if (info.hasSound) {
      setState({ ...state, isOpenDismissModal: true, dismissInfo: info });
    }
  };

  const turnOffSoundForTileCancel = () => {
    setState({ ...state, isOpenDismissModal: false, dismissInfo: undefined });
  };

  const turnOffSoundForTile = () => {
    props.turnOffSoundForTile({
      bcu_wifi_mac: state.dismissInfo?.bcu_wifi_mac,
    });
    setState({ ...state, isOpenDismissModal: false, dismissInfo: undefined });
  };

  const clearNurseCall = (mac) => {
    props.clearNurseCall({
      mac: mac,
    });
  };

  const disconnectNurseCall = (mac) => {
    props.disconnectNurseCall({
      mac: mac,
    });
  };

  const playSound = (tone = state.usedTone) => {
    if (isPopupOpenRef.current) return;
    let audio;

    if (tone === 1) {
      audio = document.getElementById("alertAudio1");
    } else if (tone === 2) {
      audio = document.getElementById("alertAudio2");
    } else if (tone === 3) {
      audio = document.getElementById("alertAudio3");
    }

    if (!audio) return;
    audio.volume = 1;
    audio.play();
    audio.loop = false;
  };

  const stopSound = () => {
    let audio;

    if (state.usedTone === 1) {
      audio = document.getElementById("alertAudio1");
    } else if (state.usedTone === 2) {
      audio = document.getElementById("alertAudio2");
    } else if (state.usedTone === 3) {
      audio = document.getElementById("alertAudio3");
    }

    if (!audio) return;
    if (audio && !audio.paused && !audio.stopped) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const resize2 = function () {
    // console.log("resize");
    // props.openLoading();
    // let length = listFilter?.length;
    let length = props.liveTileList?.length;
    const ntiles = state.ntiles;

    // calc rows, cols
    let cols, rows;
    if (length <= ntiles) {
      cols = length;
      rows = 1;
    } else {
      cols = ntiles;
      rows = Math.floor(length / ntiles) + (length % ntiles !== 0 ? 1 : 0);
    }

    // width, height
    let screen_width = $(window).width();
    let screen_height = $(window).height() - 40;

    // calc tile in px
    let tileWidth = screen_width / cols;
    let tileHeight = tileWidth - tileWidth * 0.17;

    if (tileHeight > screen_height) {
      tileHeight = screen_height;
      tileWidth = (tileHeight * 100) / 83;
    } else if (tileHeight * rows > screen_height) {
      tileWidth = tileWidth - Math.ceil(17 / cols);
      tileHeight = tileWidth - tileWidth * 0.16;
    }

    $(":root").css("--tile-width", `${tileWidth}px`);
    $(":root").css("--tile-width-px", `${tileWidth}px`);
    $(":root").css("--tile-height", `${tileHeight}px`);
    $(":root").css("--live-tile-cols", `${cols}`);
  };

  let timer1, timer2, timer3;
  const doBlink = () => {
    const blink = function () {
      $(".status-value-resp").css({ opacity: "1.0" });
      $(".status-value-pr").css({ opacity: "1.0" });
      timer1 = setTimeout(() => {
        $(".patient-name").removeClass("sound-icon-trigger-white");
        $(".patient-name").removeClass("sound-icon-trigger-red");
        $(".sound-icon-trigger .patient-name").addClass(
          "sound-icon-trigger-red"
        );
        $(".blinking").css({ opacity: "0" });
      }, 500);
      timer2 = setTimeout(() => {
        $(".patient-name").removeClass("sound-icon-trigger-white");
        $(".patient-name").removeClass("sound-icon-trigger-red");
        $(".sound-icon-trigger .patient-name").addClass(
          "sound-icon-trigger-white"
        );
        $(".blinking").css({ opacity: "1.0" });
      }, 1000);
      timer3 = setTimeout(blink, 1500);
    };

    blink();
  };

  const clearBlink = () => {
    if (timer1) clearTimeout(timer1);
    if (timer2) clearTimeout(timer2);
    if (timer3) clearTimeout(timer3);
    $(".tile div div").css("opacity", "1.0");
    $(".status-value-resp").css({ opacity: "1.0" });
    $(".status-value-pr").css({ opacity: "1.0" });
    $(".icons-wrapper img").css({ opacity: "1.0" });
  };

  let timer4, timer5, timer6;

  const doMove = () => {
    const move = function () {
      timer4 = setImmediate(() => {
        $(".move-l").css({ visibility: "hidden" });
      });
      timer5 = setTimeout(() => {
        $(".move-l").css({ visibility: "visible" });
      }, 250);
      timer6 = setTimeout(() => {
        move();
      }, 500);
    };

    move();
  };

  const clearMove = () => {
    if (timer4) clearImmediate(timer4);
    if (timer5) clearTimeout(timer5);
    if (timer6) clearTimeout(timer6);
    $(".move-l").css({ visibility: "visible" });
  };

  const doFading = () => {
    const fading = function () {
      $(".fade-opacity").fadeTo(3000, 0.3);
      $(".fade-opacity").fadeTo(3000, 1.0);

      setTimeout(() => {
        $(".icons-wrapper img").stop(true);
        $(".icons-wrapper img").css({ opacity: "1.0" });
        fading();
      }, 6000);
    };

    fading();
  };

  useLayoutEffect(() => {
    if (props.liveTileList?.length > 0) {
      resize2();
    }

    doBlink();
    doMove();
    doFading();

    // resize
    window.addEventListener("resize", resize2);

    return () => {
      clearBlink();
      clearMove();
      window.removeEventListener("resize", resize2);
    };
  }, [props.liveTileList?.length, state.ntiles]);

  // window.onresize = debounce(resize2, 200);

  const changeAlarmTypeCancel = () => {
    setState({
      ...state,
      isOpenSelectAlarmType: false,
      selectedTone: state.usedTone,
    });
  };

  const changeAlarmTypeConfirm = () => {
    localStorage.setItem(`${user}_OAC_LT_TONE`, state.selectedTone);
    setState({
      ...state,
      isOpenSelectAlarmType: false,
      usedTone: state.selectedTone,
    });
  };

  const handleChangeRadio = (e) => {
    // navigator.vibrate(2000);
    playSound(Number(e.target.value));
    setState({ ...state, selectedTone: Number(e.target.value) });
  };

  return (
    <div className="bg-white">
      <div className="live-tile">
        <div className="filter">
          <div className="left">
            <div
              className="inline hamburger selected"
              onClick={() => setState({ ...state, openSideBar: true })}
              onMouseLeave={() => setState({ ...state, openSideBar: false })}
            >
              <i
                onTouchEnd={() =>
                  setState({ ...state, openSideBar: !state.openSideBar })
                }
              >
                ☰
              </i>
              <SideBar
                hideLiveViewBtn={true}
                isOpen={true}
                className={state.openSideBar ? "show" : "hide"}
              />
            </div>
            <div className="inline live-tile-logo">{t(`OAC LiveView`)}</div>
            <div className="inline live-tile-centre">
              [ {localStorage.getItem(`${props.role}_OAH_CENTRE_NAME`)} ]
            </div>
          </div>
          <div className="align-right">
            {!isPopupOpen && (
              <div
                className="select-intercom"
                onClick={openIntercom} // Open Intercom on button click
              >
                <img src={intercomImage} />
              </div>
            )}
            <div className="select-number">
              <span>{t(`Display`)}</span>
              &nbsp;
              <EspDropdown
                key={"select-number-1"}
                placeholder={`Select Level`}
                value={{ value: state.ntiles, label: state.ntiles }}
                options={[
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                  { value: 4, label: 4 },
                  { value: 5, label: 5 },
                  { value: 6, label: 6 },
                  { value: 7, label: 7 },
                  { value: 8, label: 8 },
                ]}
                onChange={(val) => {
                  setState({ ...state, ntiles: val.value });
                  localStorage.setItem(
                    `${user}_OAC_LT_ITEMS_PER_ROW`,
                    val.value
                  );
                }}
              />
              &nbsp;
              <span>{t(`tiles per row`)}</span>
            </div>
            <div
              className="select-alarm-type"
              onClick={() =>
                setState({ ...state, isOpenSelectAlarmType: true })
              }
            >
              <img src={AlarmTypeImg} />
            </div>
            <div className="user-dropdown">
              <UserDropdown
                auth={true}
                audio={props.audio}
                isReconnect={props.isReconnect}
                versions={props.versions}
                onOffAudio={props.onOffAudio}
                handleLogout={props.handleLogout}
                handleOpenPP={props.openPP}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="tiles">
          {!props.liveTileList || props.liveTileList.length === 0 ? (
            <i className="no-bed">{t("No bed allocated")}</i>
          ) : undefined}
          {props.liveTileList?.map((tile) => {
            return (
              <Tile
                info={tile}
                ntiles={state.ntiles}
                handleTurnOffSound={handleTurnOffSound}
                openNurseCall={openNurseCall}
              />
            );
          })}
        </div>
        {state.isOpenDismissModal ? (
          <IrespModal
            clazz="dismiss-alarm"
            title={t(`Clear alarm(s)`)}
            contents={[
              <div className="">
                {t(`For resident`)}:&nbsp;
                <span className="color-red">
                  {state.dismissInfo.patient_name}
                </span>
              </div>,
              <div className="mt-lg-5 mt-md-5 mt-sm-5">
                {t(`Clear following actionable alarms`)}:
                {state.dismissInfo.soundArray.map((alarm) => {
                  return (
                    <>
                      <br />
                      <span className="color-red font-weight-normal">
                        - {t(alarm)}
                      </span>
                    </>
                  );
                })}
              </div>,
            ]}
            buttons={[
              <button onClick={turnOffSoundForTileCancel}>
                {t(`Cancel`)}
              </button>,
              <button onClick={turnOffSoundForTile}>{t(`Confirm`)}</button>,
            ]}
          />
        ) : null}
        {state.isOpenSelectAlarmType ? (
          <IrespModal
            clazz="change-alarm-tone"
            title={t(`Alert Tone Settings`)}
            contents={[
              <div className="mt20">
                {t(`Ensure that audio volume is not turned off.`)}
              </div>,
              <div className="mt20 mb10">{t(`Select an alert tone:`)}</div>,
              <div className="ml20">
                <input
                  onClick={handleChangeRadio}
                  value={1}
                  checked={state.selectedTone === 1}
                  name="alert_tone"
                  id="alert1"
                  type="radio"
                />
                <label for="alert1">{t(`Alert-1`)}</label>
              </div>,
              <div className="ml20">
                <input
                  onClick={handleChangeRadio}
                  value={2}
                  checked={state.selectedTone === 2}
                  name="alert_tone"
                  id="alert2"
                  type="radio"
                />
                <label for="alert2">{t(`Alert-2`)}</label>
              </div>,
              <div className="ml20 mb10">
                <input
                  onClick={handleChangeRadio}
                  value={3}
                  checked={state.selectedTone === 3}
                  name="alert_tone"
                  id="alert3"
                  type="radio"
                />
                <label for="alert3">{t(`Alert-3`)}</label>
              </div>,
            ]}
            buttons={[
              <button onClick={changeAlarmTypeCancel}>{t(`Cancel`)}</button>,
              <button onClick={changeAlarmTypeConfirm}>{t(`OK`)}</button>,
            ]}
          />
        ) : null}
      </div>
      {isPopupOpen && popupInfoRef.current && (
        <div className={"iresp-modal"}>
          <NurseCall
            isIntercomOpen={isIntercomOpen}
            ref={nurseCallRef}
            pinfo={props.info} // Pass props.info to Intercom
            info={popupInfoRef.current}
            handleClosePopup={handleClosePopup}
            openIntercom={openIntercom}
            forceClose={isForceClose}
            callStart={isCallStart}
          />
        </div>
      )}

      {/* Render the Intercom popup if it is open */}
      {isIntercomOpen && (
        <div className={"iresp-modal"}>
          <Intercom
            info={props.info} // Pass props.info to Intercom
            nurses={props.nurses}
            onClose={closeIntercom} // Handle close event
            forceClose={isForceClose}
            createRoom={createRoom}
          />
        </div>
      )}
    </div>
  );
}
