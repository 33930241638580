import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./EspFullModal.css";
import moment from "moment";
import _ from "lodash";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./customRcTimepickerCss.css";
import audioFile from "../../audioes/Alert3-A62.mp3";

export function EspFullModal(props) {
  const [state, setState] = useState({
    openPanel: {},
  });
  const [showRetryButton, setShowRetryButton] = useState(true); // Control visibility of "Continue Retry" button

  // Create an audio element with useRef to persist across renders
  const audioRef = useRef(new Audio(audioFile));
  audioRef.current.loop = true; // Set audio to loop

  // Control audio playback based on component visibility and button click
  useEffect(() => {
    const audio = audioRef.current;
    if (props.isOpen) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0; // Reset audio to the beginning
    }

    return () => {
      audio.pause();
      audio.currentTime = 0; // Cleanup audio on component unmount
    };
  }, [props.isOpen]);

  const handleContinueRetry = () => {
    console.log("handleContinueRetry");
    const audio = audioRef.current;
    audio.pause();
    audio.currentTime = 0; // Stop audio when "Continue Retry" is pressed
    setShowRetryButton(false); // Hide "Continue Retry" button after click
    if (props.onRetry) props.onRetry();
  };

  const limitRange =
    props.selectedDate &&
    moment(props.selectedDate).format("YYYYMMDD") ===
      moment().format("YYYYMMDD");

  useEffect(() => {
    setState({
      ...state,
      from: moment().hour(0).minute(0),
      to: limitRange
        ? moment().hour(moment().hours()).minute(moment().minute())
        : moment().hour(23).minute(59),
    });
  }, [props.isOpen]);

  const handleChange = (value, input) => {
    let newState = { ...state };
    if (input === "from" && value.diff(state.to, "seconds") < 0) {
      newState = { ...state, [input]: value };
    } else if (input === "to" && value.diff(state.from, "seconds") > 0) {
      newState = { ...state, [input]: value };
    }

    setState({ ...newState });
  };

  const ignoreHours = () => {
    const currentHour = moment().hours();
    return _.range(currentHour + 1, 24);
  };

  const ignoreMinutes = (selected) => {
    const currentHour = moment().hours();
    if (moment(selected).hours() < currentHour) return _.range(60, 60);
    const currentMin = moment().minutes();
    return _.range(currentMin, 60);
  };

  if (!props.isOpen) {
    return null;
  }

  return (
    <div className="esp-full-screen-overlay">
      <div className="overlay-content">
        {props.loading && (
          <img
            className="overlay-loading"
            src={props.LoadingImage}
            alt="Loading"
          />
        )}
        <br />
        <br />
        <p className="overlay-message">{props.content}</p>

        <div className="button-container">
          {showRetryButton && (
            <button className="blue-button" onClick={handleContinueRetry}>
              Continue Retry
            </button>
          )}
          <button className="blue-button" onClick={props.handler}>
            Return to login
          </button>
        </div>
      </div>
    </div>
  );
}

EspFullModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  loading: PropTypes.bool,
  LoadingImage: PropTypes.string,
  onRetry: PropTypes.func,
  handler: PropTypes.func.isRequired,
};

export default EspFullModal;
