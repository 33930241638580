// CommandCreator.js

let lastCallTime = null;

const byteArrayToHex = (byteArray) => {
  return Array.from(byteArray, (byte) => {
    return ("0" + (byte & 0xff).toString(16)).slice(-2);
  }).join("");
};

const reverseHex = (hexString) => {
  let result = "";
  for (let i = 0; i < hexString.length; i += 2) {
    result = hexString.substring(i, i + 2) + result;
  }
  return result;
};

const CommandCreator = {
  startAudioSession: () => {
    return "500000";
  },

  clientAudioData: (sequenceNumber, audioPayload) => {
    const currentTime = Date.now();
    if (lastCallTime !== null) {
      const interval = currentTime - lastCallTime;
      //  console.log(`Interval: ${interval}ms`); // Example log, replace with actual use
    }
    lastCallTime = currentTime;

    const sequenceNumberHex = sequenceNumber.toString(16).padStart(2, "0");
    let audioPayloadHex = byteArrayToHex(audioPayload);
    // audioPayloadHex = reverseHex(audioPayloadHex);

    const payloadLenHex = (
      sequenceNumberHex.length / 2 +
      audioPayloadHex.length / 2
    )
      .toString(16)
      .padStart(4, "0");
    return `51${payloadLenHex}${sequenceNumberHex}${audioPayloadHex}`;
  },

  buttonPressedCommand: () => {
    return "520000";
  },

  buttonReleasedCommand: () => {
    return "530000";
  },

  endAudioSession: () => {
    return "540000";
  },

  startCallWaiting: () => {
    return "550000";
  },

  endCallWaiting: () => {
    return "560000";
  },

  getSoundArray: () => {
    return [
      "51000400b8fffe",
      "51000401b8fffe",
      "51000402b8fffe",
      "51000403b8fffe",
      "51000404b8fffe",
      "5100360548800908420a6a36ebdc47b3d6acef9724db548d1f6c50174ab4fffe00000000000000000000000000000000000000000000000000",
      "51002e06488236f9aa18bb58cb30dbf4e058d5658dbaccd65cb4953ca03bdf0ad86336eabb892e53ede21538ba06380980",
      "51003007488c093ec77093bf36683097dbeeb78768ecd4973e342c1cad78a061f6185cb57d220df11ac32b07dfd74bbe413430",
      "510037084882578273fbe3df3d86637640258f5825d6cb850c6a042ccd6dd39ea07b25d052192a2a661557e3a96033672e9a3a0a141e94f44b42",
      "51003009488275e2731690b9a63d06ec4f3203faa7d16fdb51166237a314ba2f03fc1b3b9e82d2a2e55fe9070cdce055c98480",
      "5100300a488d99061f7d1e866a8d64ca7bd941f095a9f81787f19eb6342cead09392f14f4a00c191f3f27aba01dd37b2e381f8",
      "5100320b488275e273164af9e3c3156a486d08b0bcb1722f0be9bd89d038c108e2922c76b1eecaf381ca7fec0b69fe3abc9c48e090",
      "5100370c488d981c278925949778cb86b3483b24ea50c4ce2e9fcd0e4a4f72ed2518f9185a556bc73ee7b9eba4311b5d5f0b73ae60d979ec0270",
      "5100320d488275e3150db10c5da718c799a9ce636e499af6250f61e715743d4208cc4ec5bdb9e389d644ae98615b36e5ff5cf1ab1e",
      "5100390e488275e5a9a116a70482486d5d76d2f3b7d4e46c9237aa3a905e9a8c2474a4e13bc15cd98ee32923079ba4f8ddf32738c4cbecd41ca77280",
      "51003d0f4882769254cdc69015c4749de93a477833cfbab1878e7bcec3eb0bd7f232c0ca6bb86235bba3aae55cfca04bfd4733c4a77029bb5b3a86bebf6a2193",
      "51003910488d9cd09d51d34437ebab84298d78572ee4890d7b71e3882a132812a6e17429d36329403ec896fb1bf1054db02481d149e92169af93fec2",
      "510033114882a2e2f869bc450b7d3863c7df27cd24ebf28c4661ae68fbecbb7ac4df20a0d10d638fc9ded50a3894ff5ba91ac136401d",
      "510034124882a2e31f3f0b4390f3b56c1c546d684b49d01a1dd0dcfd4248bd2bc8256f95a0dedbd229322c30dfb2970f0b0b68185f4e80",
      "51003613488e20e4e1ded23aaff3dd02cbbca06d3b5c1167a8c031cb490edca07d5441e7ff9dcfe1c8c278493e43adca44ff192cd4a94a29f2",
      "510032144882a2e188b412e19d59347c86d96019a7cc7cdf7ac3f952523dc98e5427be42f9c49bdeba6f09a6a5049fb273b7531f61",
      "5100381548829ff3198af55ca8388ed4a57f65fe15cbaff2a7a5adb2bcf53e96e7486744448bcd74be0ffbecf670424e4570b3e28c9b54f55f47a8",
      "51003316488e23c61f89b35b98fc99be439a2bcf9626bd88952d2741959039a9881ea3002df20e839b064d056eadf8e96206ad84dcab",
      "51002f17488e4a762a39e3627da4a5d1ceb698483691692842f5ece3a9c6fd2a688d4bb9711e9bab887e0bfc44c8b192a91c",
      "510037184882c0fec8e774d90f388b9dcf698dd3299712ce965225996b0fb3b9b94dfcbc8cbc4c9837ac71c93b76796032e20ffe075fb57cb880",
      "51003119488e20e4e1dbed1dc30213ab52265cba93839c27085f987006beb7c9b72063bb84278fc9318a0f8585d54245f3de9f80",
      "5100331a4882a572baae0e7de9e547e6af051073429fd03dbfbed6a7978b157aa3a01e39e0c2ce151b67279b0e6b610b17736abd6332",
      "5100301b4882c1957e380a54000617a37e09f62a79bfc4051b53ae2c9fb6f64fdceaf54acfce85329840b6a20f6df5806de43e",
      "5100341c4882c7e62726d31dc2fb469450ee989043fb08ed6a002c0a6816f851a1c2d165de30a59a4f024898737c02cbd7f15ae8cddd0f",
      "5100321d4882a06061251bad1ee42ba2d76990e5895535623dde588c1cbba92c1455845ca58dbbc11476191494b316bdafbd8021d0",
      "5100321e488e1927933f9ff7aa10b073d08f509396c72024ffa6e39d08e44a1914a4b00cef8f0e180fcf6d778d77f1639806942280",
      "5100361f488e192928f75cd89ddac9bf6ef758bf1c6f6b578efd119769f463cf3f79852aec87453f5d062a9deff00bfea6d6b820e75da1d240",
      "51002f204882a2ec23364f52c121f73ca321bdacbb6e3350ba11f019713ba148af365207763cb2a9be5ed7eccb53b0e3c696",
      "51003521488e20e1fd2149b6016e2adb614f969294da222aa56ad67040eb1cae42292905785151e96d9b4811b3c5fb0d268eeb0063485640",
      "510030224882a2e272cdc8ea3835a48dabcdee7e791e1bc00743fbfc9b5618ca34e791e037842d336acabf9e97ea517e974840",
      "51003523488e192395d66e1325ba9ce075be708764c13c1e083fd2c7bb29247e50e4a4194508af1d4f16e859c9dbfcff30d82fafb68df04c",
      "51002f244882a05fdd762ce2c331ecf34fc34b3a954958690c1b1fa586802c1cd9399da96c829a98aeef3fb8b95c613347d4",
      "51003125488e20e4e0f40152115559756501d746418388688555ba8081d269f644495f2bd571b9e85551db8b296decdb6711e8c0",
      "51003626488e344360545316fd6bbb93f2146e0f733e40a050b14b8b40cfa148b04a458e4c7c734df7b739e55afc48b0a17a4ed081fa073820",
      "51003027488eb38c7d994dafa78002416e4461eaf2e63e15fc956bd780fe2dd1ff940d37910874c52ce20b189dca3cf1731c16",
      "51003a2848830ce3b3c2c0c1f4b59208e6632c953e40a04c61aa7d3af53ea0f546f0e38c0de8db80f95fd5bf555cbfbbcb22248316403f5409a9a921c0",
      "51003f2948b3c7226be5f5e82769da612fe04904ed51eb25dcc2182783d79936c2b4cbfe6c9b664d2851e03edc23bfd475cd2d43c96453c3cea7176f37651f3705c0",
      "5100342a48b1f46333350f478ca2606e9fa1da7e0e3fdef796b82fcb610cb9f047b9cb08bc90e4f49e40b451b06acd8de44ab81a0bcdc0",
      "5100372b48b1770601a707d17900ec7fc20c044786ed6191356ea49a76d07325b6c7c74392cb8061fa1a3867678f656a93a295147bac4ba97459",
      "5100392c48ae61cb0873acfa9723e932fbe8e32db0e2e5187660cb9f01822bf8d3192653bc132baec65d6c04e338b314adbc72c8bdbc5c7c825cbdce",
      "5100442d48acfd37bee7ca50ffb1edf922a4d2441534165b97529af640a64b65c31294d590994bc0c7979966c57fb57e9276ef6c884173f7e70c41d108768a1603af7122793654",
      "51003b2e48ac5ec8d0ae1f10286c3bb338594cd717c00bbad25381f64b8ad6381dbc244d450ac362aad32e323c414ce40ba9713754c7b861cb9568fdf87e",
      "5100402f48a946adb6e2e6bb5c9e260d2133ec4eabaa93544da4e0b121013e514efa633bdf7bfc0f0d6f5cb47e97a4158e5beef865256b1b7d02078652d13a71786340",
      "51003d3048a81f8ab21d5af7ec47c5e27ba2a8cd312326b357a42196167b46340915b48acb202a38ba8d290f319a17dbd65300a58af12ea444a73fde754239e0",
      "51003c3148a90c8ce9add6aacb12e1b2a2c7a1f709c30716d63ffd4843c7da973ebb8773b45dff55dba8eaf54f8c9d71e7c26c8ceba50af0b0457ded4e4f08",
      "5100393248a7e845bfc02b80c50821a0b6d8f41d969e111b8ee888a2101caf5804b18ab5bfb1e61d1f3c8063f43813a7ed27b359866a59604af3b1c8",
      "51003e3348a7cf248c01e30f6148241cfe28b6ad7fd86e134f9d1dff1696e52587a54235c048e3f6f50c67dcf6a6cb7fe7481a5c504ee18ec44c48e397bf6413e1",
      "51003c3448a7cfd4b8329effd1c2ddb3a0ad281e9811dddbeed20af6964ddef8b62fb3d9cbeece4ab6417f6c9dda447077997bfc9eaf1f2b16c2f4a0299cfb",
      "51003b3548abf1670844d64a777ab67dffadde4fa9fbf17e345237d47013ccd507c6907cf92f0f0fd41b9e7c956ed7c927aecd6dfa2e055ee29d84b010ec",
      "5100343648ae9a3022d084e7246e7b0b36bc7a2e8c655832f1e4156da1bb5b109d055a454cdf33299268e4503ae0c1bcae60fb11f71950",
      "5100353748ac6c4021a1ab5e3f7c2525de035faf69b895264fe594ddb0ea1b106efe64f439fe26694519716fe611ee320dbb388968162dc0",
      "5100343848ab4dc69a0b0321b71f5f1f77605a750dbc3ca6e49653e1d5e5118e69db7a88e0e0881fd43e38beb8355105d0360255ad7740",
      "5100393948be9e440804c0f84f1a107b817b2b8044b78656066a49fe39049832f855ffd3858460c14deb5349ecdcb4c1399a07ef63bef3c7e001d180",
      "51003c3a48a7e393c91943d3d3a69233212468826863e8ca6c50d3b5007df805232d0b40c46360621810900d5f72488740714c45c1e1a59edf3ee3b8567f80",
      "51003c3b48be88cea560505aa4ee560cfc5dea7c9fc6a5148162467acb46a253c0eb7504d9e011efd194ba77955d8c936a77d3270e9a7b82eef6a006920690",
      "5100363c48be8a31f78701ae3f3f84e82a6c36d754d888a78995a52c52698ee5e4448cd7cbb380fb6b2b63c7830fd9fcb9295d8d0fc3513606",
      "51002b3d483d1de60cf32022c6eee6366a4b14d58e8920f3440b5cec11eb27ed6773eab115e7afd6b0cc0304f69a",
      "51002a3e483d1a45dca7ae4c243b895e30dde9a08e15046e6e045be07e63f96fb7fbd2f49e04161ca9e1a92380",
      "5100253f483d1df1fbdc3717818b8b844cb8e0b50a0bdb936a34ed32f654777d697ea3ef34d25c38",
      "51002e40483d201f0f7b14b7356f8939df899bccf96672929f637002480a306a17e9001c843f2199774fdd9d92d994ae80",
      "510030414883958da26e9305a1a9c41cf3fb574d29ace8cec89ea79b278b99368a08e05da7284c864a1e65c1f651aa972ccdb8",
      "5100354248834af62cab05e0340ec413e0fbded10cb5ac6606d649ea9fad1c6a6e7c91ced944f36532262b730d41a0553f463aeec2923c80",
      "5100364348a7cf2744aed1607e053fc556df171a85e080a7b6badd07d5939fa60132651821404ce1caec4b6aa79626213586320647ccde6e1c",
      "5100374448be88cce5f4ff97c14507d133549fd95b54b4604320ab72538ac8b2bcd4b2f3a1456b6ac51f20ec8082066e77478d73003455112e12",
      "5100274548847977822fe9428012d5e0f3518f96484aef8bd66eac7af403a38ee592a87a920760ded01b",
      "5100554648a7e4f905b8aad6d3b037cdb43a348749b2d04dc652ac690e71b6dd405386699b3e5f48383ede290b599d3c90b0bba6578e6bd01d0e2916806c761265511a4e37ee9d3709be55a2fafd42eedf048601064e2702",
      "51003947b8f12dc93a1a3851991cc143d478143adf9432fb0f744783ec3e5c323d9f691656ae2801256ff9df0153d6b1e3cb02b550fc238f3ba489f1",
      "51003648b8d9c64004f1eb3cb0004b7b4e82358bb51b1750fa478fdce6bbc0fdd9723e1d8586f68fc0ee5ef08a9d4b720c293566fff55fa3ed",
      "51003349b8ee34172fcd93b39fadb308700571078ce2ae273a24918e12cf650f6b57e07d25a220bf61a22473431799c66e5a1b64cb61",
      "5100314ab89753cd9a48b165d15627a1a5dac6b52d2382fc507fa37d9f563d1c8918c5e19f64baf5c81edf927503e02565b8deb7",
      "5100394bb89fc1c5d97971b02af613d1846cff2f854ff35a8c2fea0340d2652cf54a4379e5f8c3d5990853a293aa3a28f0ab6e3e4faa546cbefbd67d",
      "5100314cb8076ccf63ecf844501d6a2095a7d9740a6976966de507a849c09027780a96e7c557471005a8e39f5c842b702df71581",
      "5100344db8c377da47e335ef8830474577d6336fc5a3373c8151d488f45fddfd8cbadb15fe56d79f78d0cdb46c75d72f199e2fe46dba59",
      "5100314eb8c33ebd7e6606a80a99bba2f0732127de9a38d53f41a711b022ded74878689804032c7155190e2995fe9aebe4263d59",
      "5100384fb87f7c501a24e5217ca7cced4af370d9674af35719d55052b0e630a4d3391cfc1ab0d775963a975ee2c707e7314be841e70ecc577649f5",
      "51003350b8da32a483074e4b4517dfc674f8492147b762a63fe757f2de0bbea0991dadb0e8de33fcfd1cb62f2abab87ab66e359d63b1",
      "51003851b8f4ee87fa226e167a0e347fa4cc4de9a9b626acc746af6c63ee9286f224222768eedef42552ba882ec34558ef06c7a582c79392eff9fd",
      "51003352b8eabdea75f94cd950e3029ad69fb682a59ecb143adcc1ddfdf7e28fa94cc6f73997a4ee09a894deba400d31da4f43107725",
      "51003853b87affda67d18018278dc79d3e8fd148c5f243d9f4e86a9bdfb38701b147c40ee352b577e218b9bed6bde5eea5727ba9eac3748f68fcc7",
      "51003454b8d964d408b52c32d928cef4b4e34dea780208bb932c538d3a816d3d55f7274665e9ff3679eae9718336d46b3bf933ddf66475",
      "51002c55b8957beecebed96c6e2c30c0f085c2eae9d1fad1d6e8ae2ec3635e6da9800e139ab3f0c32fe0b7b7177a9d",
      "51003856b89fab051f55fde7a178731f7ec414a2e84a755721d81c27e48d89df45f73ddfa6dae4dfcd3bcd5c03bbdee942f32ca361a970704a119d",
      "51002c57b895a11b1fb1b8f60e8ec47f8f116825b9f6cc496a0de09d4b106b18e36cddaec93a6e7e49d6c521ea279d",
      "51003458b898e0dcbcc5f46541d8a0738e73418db61ed74465a1058bf2d4562183b0bb6ba12ee4c2fcdf54e2a8405ae705b71f1d04349d",
      "51003e59b8f4da59de0922ec3ad61ae3f9450b48a23535adbce55a7b4040b0fb2f46d2f16b60b64d63df5d8906fa9fd4fd08c8820c3a8730fc8bd26452e0e8341d",
      "51003e5ab87ca077828034b28aa716063a53c23ae9bb7cc4f2c242d4419f9331d91d12b139a34eadf06bf791b75f8c60cf2cb483198818b21addddf594c7597dc0",
      "51003c5bb87c2caa592383ea05ed513c4da581a90c990eee8e1d832d1b9b92bc5dc51f03ba98a9abe1d106db27904a6949404137457dd5649ca2f5a5a549b5",
      "5100385cb877909c4a2ac2643ffbcca3ccbaa61e5fab0a945c6a473d6ff4a20eddc42fe29d18e070cd88143ce8db703da0f327a179c4ebc195a72f",
      "5100395db89f7e3de4a611f07855b1827eb68fdb2a62810596f57ff549cabee64a35b0fd68e5b651bf81772f19a57e20b429e49aed3f088c174c2b7d",
      "5100345eb8f4dda7598d5e62ad68f166ef649fe3d0f4e90adbce152d825a6fc20010bba7c4211c21c9f5b3398ab3192ab373454cc947fd",
      "51003f5fb87a2a94cb5b49e799fac6c73739afa192c8a712760ebeb4b6cb0fc96aace7e58a3918ef353c3e20dd8fbc637d5ca91ca8be7f7ecc246d1f2809cc1ea77b",
      "51003260b87c33802c64adb16b324777878bc52ece0cf0fa717ec498195f7323eb0fbe5b5bfe2148d9efe8f14ac1a6b3ea59354e8b",
      "51003061b81536aa139f80348573c26e7b7da025643d547075f2a0efe1c46b09eedb206654f4e34c5dbaa48e9d6c861341f97f",
      "51002662b8073f73f5876f557770a15f2b2de304a4cf63250eaf493ee208802697179e6a94715a2f15",
      "51003963b87c90e95edbfeefc064ce3fe2336b191f449c513af4e8defe2c2afc6fd2da66ec4c1d0fa8326a0539bae29a25b0fe5f3753140089923eff",
      "51003164b87c4ba5cac08ba418ff5e330d0454bd7d3739641e622f4233082ca51d9d590594988e166360f78912609fa446d83b6a",
      "51003065b8756e69c58f2a0d59766e8e160e1d975f16ab0ba40e023a08193fc1a4e429e67701d654b54e112463cfa319baa83b",
      "51003166b8761649ff5843d2612ff2ad323c2473d3ab680dabd24f826eabdb689bb844541b34f8d786dfb1c57f82937d0a80e73a",
      "51003967b87a6b1fa829f0da717b0e1648103f6de96f7d3d2e7b3895df5f3ea566877ff0835e5ae9f621f313458282a82325471c4ea879f6f7cffcf4",
      "51003368b876a4592b45cc511672041f3c157501b3b43b5508c73d15fb1127c711e6dd93dd785bf78066a44697157783c992068fb8be",
      "51002f69b830a23993d42e56999660c0a6f4ccb0bc347a3f956cb52fbf234c516e1073aa6c4188b4e634e04bb7df662d34c5",
      "5100366ab87c2c53e35ab76b052a72398d6d7246323485619f8e6682a5ff7b1ab9fb27d2e314bca148b2ae2e2e0ad6dacb7efcf0fa6d7f4074",
      "5100256bb8384bdcaded8e916b567bde8eeef55861b118abf8ea495b6f0f63f947dfcc26c45a1a95",
    ];
  },
};

export default CommandCreator;
