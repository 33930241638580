import { AudioPacket } from "./jitter";

export const hexToInt = (hexString) => parseInt(hexString, 16);

export const hexToByteArray = (hexString) => {
  const bytes = new Uint8Array(hexString.length / 2);
  for (let i = 0; i < hexString.length; i += 2) {
    bytes[i / 2] = parseInt(hexString.substring(i, i + 2), 16);
  }
  return bytes;
};

export const reverseHex = (hexString) => {
  let result = "";
  for (let i = hexString.length; i >= 0; i -= 2) {
    result += hexString.substring(i - 2, i);
  }
  return result;
};

export const parseMessageInBytes = (message) => {
  const prefix = message.substring(0, 2).toUpperCase();
  if (prefix !== "01") {
    console.error(`addAudioInByte: skipped ${prefix}`);
    return null;
  }

  const payLengthHex = message.substring(2, 6);
  const sequenceNumberHex = message.substring(6, 8);

  const payloadLength = hexToInt(payLengthHex);
  const sequenceNumber = hexToInt(sequenceNumberHex);

  // console.log(
  //   `payloadLength: ${payloadLength} (${payLengthHex}), sequenceNumber: ${sequenceNumber} (${sequenceNumberHex})`
  // );

  let audioData = message.substring(8, 8 + payloadLength * 2);
  //audioData = reverseHex(audioData);
  const audioBytes = hexToByteArray(audioData);

  const flags = 0; // Assuming flags are to be set elsewhere or derived from message

  return new AudioPacket(Array.from(audioBytes), sequenceNumber, flags);
};
