class JitterBuffer {
  constructor(maxSize, initialPacketsCount) {
    this.maxSize = maxSize;
    this.initialPacketsCount = initialPacketsCount;
    this.buffer = [];
    this.canServe = false;
    this.lastServeTime = 0;
  }

  addPacket(packet) {
    if (!packet) {
      console.error(
        "JitterBuffer",
        "Attempted to add a null packet to the buffer"
      );
      return false;
    }
    if (this.buffer.length >= this.maxSize) {
      //console.error("JitterBuffer", "Buffer overflow. Dropping packet.");
      return false;
    }

    this.buffer.push(packet);
    this.buffer.sort((a, b) => a.sequenceNumber - b.sequenceNumber); // Sort by sequence number

    if (!this.canServe && this.buffer.length >= this.initialPacketsCount) {
      this.canServe = true; // Now it can start serving data
    }

    return true;
  }

  getPacket() {
    const currentTime = Date.now();
    const timeSinceLastServe = currentTime - this.lastServeTime;

    if (this.canServe && timeSinceLastServe >= 8) {
      // Serve a packet every 20 ms
      if (this.buffer.length > 1000) console.log(this.buffer.length);
      this.lastServeTime = currentTime; // Update last serve time
      return this.buffer.shift(); // Serve the next packet
    } else {
      // Not enough time has elapsed, or not ready to serve
      return null;
    }
  }

  reset() {
    this.buffer = [];
    this.canServe = false;
  }
}

export { JitterBuffer, AudioPacket };

class AudioPacket {
  constructor(data, sequenceNumber, flags) {
    this.data = data;
    this.sequenceNumber = sequenceNumber;
    this.flags = flags;
    this.timestamp = new Date(); // Timestamp when the object is created
  }

  toString() {
    const hexString = this.data
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(" ");
    const timestamp = this.timestamp.toISOString(); // Format timestamp as ISO string
    return `AudioPacket{data=${timestamp} DATA IN HEX >> ${hexString}, sequenceNumber=${this.sequenceNumber}, flags=${this.flags}}`;
  }
}
