import { Host } from "../constants/host";
import download from "downloadjs";
import moment from "moment";

const apiUrlProxy =
  Host.LocalProxy ||
  window.location.protocol +
    "//" +
    window.location.hostname +
    ":" +
    Host.LocalProxyPort;
// const apiUrl = Host.NssRestApi || window.location.protocol + "//" + window.location.hostname + ":" + Host.NssRestApiPort;

const apiUrl = "https://api.oac-system.com:8443";
//const apiUrl = "https://api.oac-system.com";
//const apiUrl = "http://172.18.20.124:8088";

const apiPath = {
  Login: "api/user/login",
  CheckLogin: "api/user/is_login",
  Logout: "api/user/logout",
  GetVersion: "api/user/versions",

  GetOAHData: "api/oah/get_oah_data",
  GetNurseQrCode: "api/admin/get_nurse_qr",
  GetListBedAssignment: "api/oah/query_bed_function_status",
  AddNurse: "api/manager/add_nurse_account",
  UpdateNurse: "api/manager/update_nurse_account",
  RemoveNurse: "api/manager/remove_nurse_account",
  TransferPatient: "api/admin/transfer_patient",
  TransferHistory: "api/admin/transfer_history",
  TransferHistory: "api/admin/transfer_history",
  EnrollPatient: "api/admin/enroll_patient_record",
  DischargePatient: "api/admin/discharge_patient_record",
  DischargeHistory: "api/admin/discharge_history",
  UpdateThresholdSetting: "api/data/set_friendly_name_threshold",
  GetDataForReport: "api/data/get_sleeplog_noon",
  GetDataForLiveView: "api/data/live_view",
  EditPatient: "api/admin/edit_patient",
  ClearAlarm: "api/data/clear_alarm",
  ClearNurseCall: "api/data/clear_nurse_call",
  DisconnectNurseCall: "api/data/disconnect_nurse_call",
  ListCsv: "api/admin/list_csv",
  DownloadCsv: "api/admin/download_csv",
  ExportCsv: "api/admin/export_csv",
  SetPolicyAckVersion: "api/oah/set_policy_acked_version",

  // osp-mgr
  AssignBed: "api/manager/assign_nurse_to_bed",
  UnassignBed: "api/manager/assign_nurse_to_bed",
  GetTechQrCode: "api/manager/get_tech_qr",
  GetWifiEncryptionList: "api/manager/get_wifi_encryption",
  AddWifi: "api/manager/add_wifi",
  DeleteWifi: "api/manager/delete_wifi",
  AddCU: "api/manager/add_bed_name",
  EditCU: "api/manager/edit_bed_name",
  DeleteCU: "api/manager/delete_bed_name",
  UnbindCU: "api/manager/unbind_bcu",
  GetAddedWifi: "api/manager/get_wifi_list",
  UpdateNurseAdminPassword: "api/manager/update_nurse_admin_password",
  AuthCheck: "api/user/auth_check",
  ResetPassword: "api/manager/reset_password",
  RequestResetPassword: "api/user/send_reset_password_email",
  DoResetPassword: "api/user/verify_reset_password",

  // everyone
  // GetVersions: "api/system/get_version_detail",
};

const PostOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
};

const role = sessionStorage.getItem("OMNI2_ROLE");

export const authProvider = {
  checkAuth: () =>
    sessionStorage.getItem(`${role}_token`)
      ? Promise.resolve()
      : Promise.reject(),
  logout: async () => {
    sessionStorage.removeItem("OMNI2_ROLE");
    sessionStorage.removeItem(`${role}_token`);
    localStorage.removeItem(`${role}_OMNI2_USER`);
    localStorage.removeItem(`${role}_OAH_CENTRE_NAME`);
    localStorage.removeItem(`ADMIN_OAH_TIMEZONE`);
    localStorage.removeItem("OMNI2_TILES_OPENING");
    localStorage.removeItem("OMNI2_ALERT");
    localStorage.removeItem("OAC_POLICY_VERSION");
    localStorage.removeItem(`${role}_LOGGIN_ROLE`);

    await fetchHandleError(`${apiUrl}/${apiPath.Logout}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
    }).then((res) => {});

    return Promise.resolve();
  },
  logoutWithoutRequest: async () => {
    // localStorage.removeItem('department');
    // localStorage.removeItem('OMNI2_ROLE');
    // localStorage.removeItem('OMNI2_USER');
    // localStorage.removeItem('OMNI2_NEED_CHANGE_PASSWORD');
    // localStorage.removeItem('OMNI2_TILES_OPENING');
    // localStorage.removeItem('OAH_CENTRE_NAME');
    return Promise.resolve();
  },
  login: async (data) => {
    try {
      const result = await fetchHandleError(`${apiUrl}/${apiPath.Login}`, {
        ...PostOptions,
        body: JSON.stringify(data),
      }).then((res) => res.json());
      const { token, role, status_code, privacy, error_message, message } =
        result;
      if (status_code !== 0) {
        return Promise.reject(error_message || message);
      }
      return Promise.resolve({ token, role, privacy });
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  checkLogin: async (user) => {
    try {
      const result = await fetchHandleError(`${apiUrl}/${apiPath.CheckLogin}`, {
        ...PostOptions,
        body: JSON.stringify(user),
      }).then((res) => res.json());
      const { status_code, loggedin, error } = result;
      if (status_code !== 0) {
        return Promise.reject(error);
      }
      return Promise.resolve(loggedin);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
};

export const dataProvider = {
  getBedAllocation: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetOAHData}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },
  getBedAssignment: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetListBedAssignment}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },
  getListOfNurses: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetOAHData}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },
  getQRData: async (nurse) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetNurseQrCode}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nurse),
    }).then((res) => res.json());
  },
  addNurse: async (nurse) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.AddNurse}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nurse),
    }).then((res) => res.json());
  },
  removeNurse: async (nurse) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.RemoveNurse}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nurse),
    }).then((res) => res.json());
  },
  updateNurse: async (nurse) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.UpdateNurse}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(nurse),
    }).then((res) => res.json());
  },
  assignBed: async (bed) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.AssignBed}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bed),
    }).then((res) => res.json());
  },
  getAvailableBeds: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetOAHData}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },
  enrollPatient: async (patient) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.EnrollPatient}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(patient),
    }).then((res) => res.json());
  },
  getDischargeHistory: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.DischargeHistory}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(patient)
    }).then((res) => res.json());
  },
  getListAssignedBeds: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetOAHData}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(patient)
    }).then((res) => res.json());
  },
  getTransferHistory: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.TransferHistory}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(patient)
    }).then((res) => res.json());
  },
  transferPatient: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.TransferPatient}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  getSleepLog: async ({ patient_id, days }) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetDataForReport}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ patient_id, days }),
    }).then((res) => res.json());
  },
  editPatient: async (update) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.EditPatient}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(update),
    }).then((res) => res.json());
  },
  dischargePatient: async (patient) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.DischargePatient}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(patient),
    }).then((res) => res.json());
  },
  getThresholdSetting: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetOAHData}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(patient)
    }).then((res) => res.json());
  },
  updateThresholdSetting: async (update) => {
    return await fetchHandleError(
      `${apiUrl}/${apiPath.UpdateThresholdSetting}`,
      {
        method: "POST",
        headers: {
          Authorization: sessionStorage.getItem(`${role}_token`),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(update),
      }
    ).then((res) => res.json());
  },
  pollingData: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetDataForLiveView}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(update)
    }).then((res) => res.json());
  },
  turnOffSoundForTile: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.ClearAlarm}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  turnOffSoundForTile: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.ClearAlarm}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },

  clearNurseCall: async (info) => {
    console.log("token --------");
    console.log(sessionStorage.getItem(`${role}_token`));
    return await fetchHandleError(`${apiUrl}/${apiPath.ClearNurseCall}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },

  disconnectNurseCall: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.DisconnectNurseCall}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },

  getTechQRCode: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetTechQrCode}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(update)
    }).then((res) => res.json());
  },
  getWifiEncryptionList: async () => {
    return await fetchHandleError(
      `${apiUrl}/${apiPath.GetWifiEncryptionList}`,
      {
        method: "POST",
        headers: {
          Authorization: sessionStorage.getItem(`${role}_token`),
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(update)
      }
    ).then((res) => res.json());
  },
  getAddedWifi: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetAddedWifi}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(update)
    }).then((res) => res.json());
  },
  addWifi: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.AddWifi}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  deleteWifi: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.DeleteWifi}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  getListOfCU: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.GetOAHData}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(info)
    }).then((res) => res.json());
  },
  addCU: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.AddCU}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  editCU: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.EditCU}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  deleteCU: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.DeleteCU}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  unbindCU: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.UnbindCU}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  updateNurseAdminPassword: async (info) => {
    return await fetchHandleError(
      `${apiUrl}/${apiPath.UpdateNurseAdminPassword}`,
      {
        method: "POST",
        headers: {
          Authorization: sessionStorage.getItem(`${role}_token`),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(info),
      }
    ).then((res) => res.json());
  },
  authCheck: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.AuthCheck}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(info)
    }).then((res) => res.json());
  },
  resetPassword: async () => {
    return await fetchHandleError(`${apiUrl}/${apiPath.ResetPassword}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(info)
    }).then((res) => res.json());
  },
  requestResetPassword: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.RequestResetPassword}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  doResetPassword: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.DoResetPassword}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  sendLog: async (log) => {
    const apiUrl = "http://localhost:8080";
    return await fetchHandleError(`${apiUrl}/log`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(log),
    }).then((res) => res.json());
  },
  exportCsv: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.ExportCsv}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          return {
            success: true,
            status: resp.status,
            data: await resp.blob(),
          };
        } else if (resp.status === 500) {
          return {
            success: false,
            status: resp.status,
            data: await resp.json(),
          };
        }
        return {
          status: resp.status,
          success: false,
        };
      })
      .then(({ data, status, success }) => {
        if (success) {
          download(
            data,
            `${moment().format("YYYY_MM")}-${info.patient_name}.csv`,
            "text/csv"
          );
          return {};
        } else if (status === 500) {
          const { error } = data;
          return { error };
        }
        return { error: "ERROR" };
      });
  },
  listCsv: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.ListCsv}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(`${role}_token`),
      },
      body: JSON.stringify(info),
    }).then((res) => res.json());
  },
  downloadCsv: async (info) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.DownloadCsv}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(`${role}_token`),
      },
      body: JSON.stringify(info),
    })
      .then((res) => res.json())
      .then(async (json) => {
        const { url, status_code } = json;
        if (status_code === 0) {
          return fetch(url, { method: "GET" })
            .then(async (resp) => {
              if (resp.status === 200) {
                return {
                  success: true,
                  status: resp.status,
                  data: await resp.blob(),
                };
              } else if (resp.status === 500) {
                return {
                  success: false,
                  status: resp.status,
                  data: await resp.json(),
                };
              }
              return {
                status: resp.status,
                success: false,
              };
            })
            .then(({ data, status, success }) => {
              if (success) {
                download(
                  data,
                  `${info.report_key}-${info.patient_name}.csv`,
                  "text/csv"
                );
                return { status_code };
              } else if (status === 500) {
                const { error } = data;
                return { error };
              }
              return { error: "ERROR" };
            });
        }

        return json;
      });
  },
  getVersions: async () => {
    try {
      const response = await fetchHandleError(
        `${apiUrl}/${apiPath.GetVersion}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        console.error(`Request failed with status: ${response.status}`);
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Request successful:", data);
      return data;
    } catch (error) {
      console.error("Error occurred during getVersions:", error);
      return { error: true, message: "Network error or fetch failure" };
    }
  },

  setPolicyAckVersion: async (version) => {
    return await fetchHandleError(`${apiUrl}/${apiPath.SetPolicyAckVersion}`, {
      method: "POST",
      headers: {
        Authorization: sessionStorage.getItem(`${role}_token`),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(version),
    }).then((res) => res.json());
  },
};

const fetchHandleError = (...args) => {
  const token = sessionStorage.getItem(`${role}_token`);

  if (
    !token &&
    window.location.href.indexOf("/login") === -1 &&
    window.location.href.indexOf("/reset") === -1
  ) {
    window.location.href = "/login";
    return;
  }

  return fetch(args[0], args[1])
    .then((response) => {
      // console.log("status", response.status);
      // console.log("message", response.message);
      if (response.status === 401) throw new Error("Unauthorize");

      return response;
    })
    .catch((e) => {
      // console.log("status", e);
      // console.log("message", e.message);
      // alert(e.message) // Mobile
      if (e.message === "Failed to fetch" || e.message === "Load failed") {
        throw new Error("Disconnect");
      } else {
        throw e;
      }
    });
};

export const useAuth = () => {
  setImmediate(async () => {
    try {
      await authProvider.checkAuth();
    } catch (e) {
      sessionStorage.removeItem("OMNI2_ROLE");
      sessionStorage.removeItem(`${role}_token`);
      localStorage.removeItem(`${role}_OMNI2_USER`);
      localStorage.removeItem(`${role}_OAH_CENTRE_NAME`);
      localStorage.removeItem(`ADMIN_OAH_TIMEZONE`);
      localStorage.removeItem("OMNI2_TILES_OPENING");
      localStorage.removeItem("OMNI2_ALERT");
      localStorage.removeItem("OAC_POLICY_VERSION");
      localStorage.removeItem(`${role}_LOGGIN_ROLE`);
      window.location.replace("/login");
    }
  });
};

export const delay = async function (timeout) {
  return new Promise((res) => {
    setTimeout(() => {
      res();
    }, timeout);
  });
};
