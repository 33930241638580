import {
  GET_DATA,
  GET_STATUS_DATA,
  SET_POLICY_VERSION,
} from "../actions/dashboard";

const initialState = {
  createDateMap: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA:
      const { patients, beds, nurses } = action.payload;
      const bedAssignmentMap = {};
      const nurseMap = {};
      const createDateMap = {};

      beds.map((b) => {
        bedAssignmentMap[b.patient_id] = b.bed_name;
        createDateMap[b.id] = b.create_on;
      });

      // extract patient map
      const patientMap = {};
      patients.forEach(
        (p) =>
          (patientMap[p.id] = {
            name: p.name,
            enroll: p.enroll,
            discharged: p.discharged,
          })
      );

      // extract nurse map
      nurses.forEach((n) => (nurseMap[n.id] = n.name));

      return {
        ...state,
        patients,
        nurses,
        nurseMap,
        bedAssignmentMap,
        beds: beds
          .filter(
            (b) =>
              b.patient_id &&
              b.patient_id !== "" &&
              !patientMap[b.patient_id].discharged
          )
          .map((b) => ({
            ...b,
            patient_name: patientMap[b.patient_id]?.name,
            enroll: patientMap[b.patient_id]?.enroll,
          })),
        patientMap,
        createDateMap,
      };
    case GET_STATUS_DATA:
      const statuses = action.payload
        ?.filter((b) => b.patient_name && b.patient_name !== "")
        .map((b) => {
          return { ...b, create_on: state.createDateMap[b.id] };
        });
      return { ...state, beds: statuses };
    case SET_POLICY_VERSION:
      return { ...state, policyAckVersion: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
