import React, { useState, useEffect } from "react";
import cx from "classnames";
import "./Tile.css";

import BreathImg from "../../../images/oac/RESP-1A-R1.png";
import HeartImg from "../../../images/oac/PR-R1.png";
import SpinnerImg from "../../../images/oac/icon_loading_2.gif";
import UpdatingImg from "../../../images/oac/icon_loading_2.gif";

import SleepingImg from "../../../images/oac/OAC_Group_1313.png";
import AwakeImg from "../../../images/oac/OAC_Group_1243.png";
import AbsentImg from "../../../images/oac/OAC_Pillow-6D-Awake-faf36b_png_2.png";
import EOLImg from "../../../images/oac/OAC_selection.png";
import BCUDisconnect from "../../../images/oac/bcu-disconnect.png";
import NurseCallButton from "../../../images/oac/touch.png";
import micImg from "../../../images/oac/ic_mic_white.png";
import micImgDisable from "../../../images/oac/mic_disable.png";
import { messageWrapper as t } from "../../../utils/message-wrapper";

import $ from "jquery";
import Marquee from "react-fast-marquee";

export function Tile(props) {
  const { info } = props;
  const [state, setState] = useState({ needMarquee: false });

  useEffect(() => {
    const mac = info.bcu_wifi_mac.replaceAll(":", "");
    const patientNameContainer = $(`.patient-name.${mac}`).width();
    const patientNameSpan = $(`.patient-name.${mac} span`).width();
    const marginLeft = 20;
    if (patientNameSpan > patientNameContainer - marginLeft) {
      setState({ ...state, needMarquee: true });
    }
  }, [info.patient_name]);

  if (!info.status) {
    return (
      <div
        className={cx("tile")}
        onClick={() => props.handleTurnOffSound(info)}
      >
        <div
          className={cx("patient-name", info.bcu_wifi_mac.replaceAll(":", ""))}
        >
          {state.needMarquee ? (
            <Marquee>
              <span>{info.patient_name}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </Marquee>
          ) : (
            <span>{info.patient_name}</span>
          )}
        </div>
        <div className={cx("icons", "disconnected")}>
          <div className="icons-wrapper">
            <img src={BCUDisconnect} />
          </div>
          <div className="bed-name">
            <span>{info.bed_name}</span>
          </div>
        </div>
      </div>
    );
  }

  if (
    info.fw_cdmcu === 2 ||
    info.fw_cdmcu === 3 ||
    info.fw_cdmcu === 4 ||
    info.fw_cdmcu === 32 ||
    info.fw_smcu === 2 ||
    info.fw_smcu === 3 ||
    info.fw_smcu === 4 ||
    info.fw_smcu === 32
  ) {
    return (
      <div
        className={cx("tile")}
        onClick={() => props.handleTurnOffSound(info)}
      >
        <div
          className={cx("patient-name", info.bcu_wifi_mac.replaceAll(":", ""))}
        >
          {state.needMarquee ? (
            <Marquee>
              <span>{info.patient_name}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </Marquee>
          ) : (
            <span>{info.patient_name}</span>
          )}
        </div>
        <div className={cx("icons", "updating")}>
          <div className="icons-wrapper">
            <img src={UpdatingImg} />
          </div>
          <div className="updating">{t("Updating...")}</div>
          <div className={cx("bed-name", `n-beds-${props.ntiles}`)}>
            <span>{info.bed_name}</span>
          </div>
        </div>
      </div>
    );
  }

  if (
    !info.status ||
    Object.keys(info.status).length < 1 ||
    info.status?.pos === undefined
  ) {
    return (
      <div
        className={cx("tile")}
        onClick={() => props.handleTurnOffSound(info)}
      >
        <div
          className={cx("patient-name", info.bcu_wifi_mac.replaceAll(":", ""))}
        >
          {state.needMarquee ? (
            <Marquee>
              <span>{info.patient_name}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </Marquee>
          ) : (
            <span>{info.patient_name}</span>
          )}
        </div>
        <div className={cx("icons", "no-data")}>
          <div className="icons-wrapper">
            <img src={UpdatingImg} />
          </div>
          <div className={cx("bed-name", `n-beds-${props.ntiles}`)}>
            <span>{info.bed_name}</span>
          </div>
        </div>
      </div>
    );
  }

  if (info.status && info.status?.pos === 240) {
    return (
      <div
        className={cx("tile")}
        onClick={() => props.handleTurnOffSound(info)}
      >
        <div
          className={cx("patient-name", info.bcu_wifi_mac.replaceAll(":", ""))}
        >
          {state.needMarquee ? (
            <Marquee>
              <span>{info.patient_name}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </Marquee>
          ) : (
            <span>{info.patient_name}</span>
          )}
        </div>
        <div className={cx("icons", "updating")}>
          <div className="icons-wrapper">
            <img src={UpdatingImg} />
          </div>
          <div className="updating">{t("Initialising...")}</div>
          <div className={cx("bed-name", `n-beds-${props.ntiles}`)}>
            <span>{info.bed_name}</span>
          </div>
        </div>
      </div>
    );
  }

  // console.log(info.model);
  // console.log(info.status?.last_update);
  return (
    <div
      className={cx(
        "tile",
        info.audibleActionError && info.hasSound && info.enableAll
          ? "sound-icon-trigger"
          : ""
      )}
      onClick={() => props.handleTurnOffSound(info)}
    >
      <div
        className={cx("patient-name", info.bcu_wifi_mac.replaceAll(":", ""))}
      >
        {state.needMarquee ? (
          <Marquee>
            <span>{info.patient_name}</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </Marquee>
        ) : (
          <span>{info.patient_name}</span>
        )}
      </div>
      <div className="status font-weight-bold">
        <div className="status-resp">
          <div className="status-img-resp">
            <img src={BreathImg} />
          </div>
          <div
            className={cx(
              "status-value-resp",
              info.isExceedBr || info.status?.status === 15 ? "color-red" : "",
              info.status?.status === 1 ||
                //info.status?.nurseCall === 1 ||
                info.status?.status === 4
                ? // || info.status?.status === 5
                  "blinking"
                : ""
            )}
          >
            {formatReading(info.status?.br, info.status?.status)}
          </div>
        </div>
        <div className="status-pr">
          <div className="status-img-pr">
            <img src={HeartImg} />
          </div>
          <div
            className={cx(
              "status-value-pr",
              info.isExceedHr || info.status?.status === 15 ? "color-red" : "",
              info.status?.status === 1 ||
                // || info.status?.status === 3
                info.status?.status === 4
                ? // || info.status?.status === 5
                  "blinking"
                : ""
            )}
          >
            {formatReading(info.status?.hr, info.status?.status)}
          </div>
        </div>
      </div>
      <div
        className={cx(
          "icons",
          info.status?.status === 0 ? "border-sleeping" : "",
          info.status?.status === 1 ? "border-sleeping" : "",
          info.status?.status === 2 ? "border-absent" : "",
          info.status?.status === 3 ? "border-awake" : "",
          info.status?.status === 4 ? "border-sleeping" : "",
          info.status?.status === 5 ? "border-sleeping" : "",
          info.status?.status === 15 ? "border-eol" : "",
          info.status?.status === 16 ? "border-sleeping" : "",
          info.status?.hr === 254 ? "bed-e1" : ""
        )}
      >
        {/* <div className="icons-wrapper">
          {formatStatusImg(
            info.status?.pos,
            info.status?.status,
            info.audibleActionError && info.hasSound && info.enableAll,
            info.isAwake,
            info.status?.nurseCall,
            props,
            info
          )}
        </div> */}
        <div className={cx("icons", { "bcu-a": info.model === "BCU-A" })}>
          <div className="icons-wrapper">
            {formatStatusImg(
              info.status?.pos,
              info.status?.status,
              info.audibleActionError && info.hasSound && info.enableAll,
              info.isAwake,
              info.status?.nurseCall,
              props,
              info
            )}
          </div>
          {info.model === "BCU-A" && (
            <div className="additional-button-wrapper">
              {info.status?.last_update == -2 ? (
                <button className="additional-button">
                  <img
                    src={micImgDisable}
                    alt="Mic"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.openNurseCall(info);
                    }}
                  />
                </button>
              ) : info.status?.status === 2 && info.status?.nurseCall != 1 ? (
                <button className="additional-button">
                  <img
                    src={micImg}
                    alt="Mic"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.openNurseCall(info);
                    }}
                  />
                </button>
              ) : null}
            </div>
          )}
        </div>
        <div className={cx("bed-name", `n-beds-${props.ntiles}`)}>
          <span>{info.bed_name}</span>
        </div>
      </div>
    </div>
  );
}

function formatReading(value, status) {
  // error first
  if (value === 254) {
    return <span className="error">E1</span>;
  } else if (value === 251) {
    return <span className="error">E8</span>;
  }

  // exceed threshold
  if (
    value === undefined ||
    value === 240 ||
    value === 255 ||
    status === 5 ||
    status === 3 ||
    status === 2
  ) {
    return <span className="spash-big font-weight-normal">--</span>;
  }

  return value;
}

// patient sitting is not used
function formatStatusImg(pos, status, isRed, isAwake, nurseCall, props, info) {
  // error first
  const errorImg = getStatusError(pos, status);

  if (errorImg !== "") {
    return errorImg;
  }

  if (nurseCall == 1) {
    // console.log("---------");
    // console.log(info);
    return (
      <div className="pos-status-group">
        <img
          src={NurseCallButton}
          onClick={(e) => {
            e.stopPropagation();
            props.openNurseCall(info);
          }}
        />
      </div>
    );
  }

  // data after
  switch (pos) {
    case 0:
      return (
        <div className="pos-status-group">
          {/*<img src={Awake1}/>;*/}
          {getStatusImg(status, isAwake)}
        </div>
      );
    case 1:
      return (
        <div className="pos-status-group">{getStatusImg(status, isAwake)}</div>
      );
    case 2:
      return (
        <div className="pos-status-group">
          <img src={AbsentImg} />
        </div>
      );
    default:
    // return <div className="flex-updating">
    //     <img src={SpinnerImg} className="img-error tile-spinner-2"/>
    //     <div className="updating">{t("Updating...")}</div>
    // </div>
  }
}

function getStatusError(pos, status) {
  if (pos === 254 || status === 254) {
    return undefined;
  } else if (pos === 251 || status === 251) {
    return undefined;
  } else if (pos === 240 || status === 240) {
    return undefined;
    // return <img src={SpinnerImg} className="img-error tile-spinner-2"/>;
  }

  return "";
}

function getStatusImg(status, isAwake) {
  switch (status) {
    case 0:
      return <img src={SleepingImg} />;
    case 1:
      return <img className="img-movement fade-opacity" src={SleepingImg} />;
    case 2:
      return <img src={AbsentImg} />;
    case 3:
      return <img src={AwakeImg} />;
    case 4:
      return <img className="img-tts fade-opacity" src={SleepingImg} />;
    case 5:
      return <img className="img-judgement fade-opacity" src={SleepingImg} />;
    case 15:
      return <img src={EOLImg} />;
    case 16:
      return <img src={SleepingImg} />;
  }
}

function formatPosImg(pos) {
  // return <img className="not-install" src={NotInstalled}/>;
  // switch (pos) {
  //     case 0:
  //         return <img src={NotInstalled}/>;
  //     case 1:
  //         return <img src={NotInstalled}/>;
  //     case 2:
  //         return "";
  //     case 254:
  //         return <img src={NotInstalled}/>;
  //     case 251:
  //         return <img src={NotInstalled}/>;
  //     case 240:
  //         return <img src={SpinnerImg} className="img-error"/>;
  //     default:
  //         return <img src={NotInstalled}/>;
  // }
}
