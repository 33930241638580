import React, { useEffect, useRef, useState } from "react";
import DisconnectImage from "../../images/disconnect.png";
import InboxImage from "../../images/mail.png";
import { UserDropdown } from "./UserDropdown";
import { ROLES } from "../../constants/roles";
import { IrespModal } from "../common/IrespModal";
import { IrespDropdown } from "../common/IrespDropdown";
import {
  messageWrapper as t,
  translateInput as ti,
} from "../../utils/message-wrapper";
import { Password } from "../../constants/default-password";
import "./ChangeLanguage.css";
import "./Mail.css";
import { Configs } from "../../constants/configs";
import cx from "classnames";
import { useIntl } from "react-intl";
import { LiveViewBtn } from "./LiveTileBtn";

export function AppBar(props) {
  const [state, setState] = useState({
    isOpen: false,
    priorityLanguage: "en",
    isAlert: "false",
  });

  const intl = useIntl();

  const ChangeLanguage = {
    Title1: intl.formatMessage({ id: "Password to access Developer settings" }),
    Title2: intl.formatMessage({ id: "Developer Language setting" }),
    Content1: intl.formatMessage({
      id: "Enter password to access Developers settings:",
    }),
    Content2: intl.formatMessage({ id: "Developers language settings:" }),
    English: intl.formatMessage({ id: "English" }),
    Japanese: intl.formatMessage({ id: "Japanese" }),
    Password: intl.formatMessage({ id: "Enter Password" }),
    Invalid: intl.formatMessage({ id: "Invalid secret backdoor password" }),
  };

  useEffect(() => {
    const fiveClickEvt = function (evt) {
      if (evt.detail === 5) {
        setState({ ...state, isOpenChangeLanguageEnterPassword: true });
      }
    };

    const el = document.getElementById("irespLogo");

    if (el) {
      el.addEventListener("click", fiveClickEvt);
    }

    return () => {
      el.removeEventListener("click", fiveClickEvt);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const isAlert = localStorage.getItem("OMNI2_ALERT");
      if (state.isAlert !== isAlert) {
        setState({ ...state, isAlert: isAlert });
      }

      const isLogout = !sessionStorage.getItem(
        `${sessionStorage.getItem("OMNI2_ROLE")}_token`
      );

      if (
        isLogout &&
        window.location.href.indexOf("/login") === -1 &&
        window.location.href.indexOf("/reset-password") === -1
      ) {
        window.location.href = "/login";
      }
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [state.isAlert]);

  // Close live tile page
  window.addEventListener("beforeunload", function (e) {
    if (!props.liveTile) return;
    props.liveTile.close();
  });

  // const openLiveView = () => {
  //     // localStorage.setItem("OMNI2_TILES_OPENING", "false");
  //     if (props.liveTile) {
  //         props.liveTile.close();
  //     }
  //     let liveTile = window.open("/cc-admin-live-tile", "_blank");
  //
  //     props.setLiveTile(liveTile);
  // };

  // useAlertButton(openLiveView);

  const langOptions =
    [] ||
    props.languageOptions.map((lang) => ({
      value: lang.value,
      label: t(`${lang.label}`),
    }));

  const renderAlertImage = () => {
    if (props.location?.pathname?.indexOf("login") > -1) {
      return <div />;
    }

    return (
      <div
        className="alert-img"
        onClick={() => {
          props.clickedLiveView();
          props.history.push("/cc-admin-live-tile");
        }}
      >
        {state.isAlert === "true" ? (
          <LiveViewBtn />
        ) : (
          <LiveViewBtn isBlack={true} />
        )}
      </div>
    );
  };

  const renderDisconnect = () => {
    return (
      <div
        className={cx(
          "alert-img",
          "disconnected-img",
          localStorage.getItem("OMNI2_DISCONNECTED") === "true" &&
            sessionStorage.getItem(
              `${sessionStorage.getItem("OMNI2_ROLE")}_token`
            ) !== undefined &&
            window.location.pathname.indexOf("/login") === -1
            ? ""
            : "d-none"
        )}
        id="disconnect-btn"
      >
        <img src={DisconnectImage} />
      </div>
    );
  };

  const renderInbox = () => {
    return (
      <div
        className="alert-img"
        id="mail-btn"
        onClick={() => {
          if (sessionStorage.getItem("OMNI2_ROLE") === ROLES.CcAdmin) {
            if (props.history.location.pathname === "/cc-admin-inbox") {
              props.getInboxMessage();
            } else {
              props.history.push(`/cc-admin-inbox`);
            }
          } else if (
            sessionStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin
          ) {
            if (props.history.location.pathname === "/nurse-admin-inbox") {
              props.getInboxMessage();
            } else {
              props.history.push(`/nurse-admin-inbox`);
            }
          }
        }}
      >
        <img src={InboxImage} width={35} height={24} />
        {props.mailUnread > 0 ? (
          <span className="mail-cnt">
            {props.mailUnread < 1000 ? props.mailUnread : "999+"}
          </span>
        ) : undefined}
      </div>
    );
  };

  // const handleOpenLiveView = () => {
  //     if (props.liveViewIsOpen) {
  //         return;
  //     }
  //     window.open("/live-tile");
  // };

  const handleChangeCancel = () => {
    setState({ ...state, isOpenChangeLanguage: false });
  };

  const handleChangeConfirm = (e) => {
    e.preventDefault();
    // if (!state.priorityLanguage || state.priorityLanguage === "") {
    //     setState({...state, devLanguageError: "Language must be not empty"});
    //     return;
    // }
    // localStorage.setItem("OMNI2_PRIORITY_LANGUAGE", state.priorityLanguage);
    setState({ ...state, isOpenChangeLanguage: false });
    props.changeLanguage(state.priorityLanguage);
  };

  const handleEnterPasswordCancel = () => {
    setState({ ...state, isOpenChangeLanguageEnterPassword: false });
  };

  const handleEnterPasswordConfirm = (e) => {
    e.preventDefault();

    if (
      state.devPassword !== Password.DevMode &&
      state.devPassword !== Password.DevMode2
    ) {
      setState({ ...state, devPasswordError: ChangeLanguage.Invalid });
      return;
    }
    setState({
      ...state,
      isOpenChangeLanguageEnterPassword: false,
      isOpenChangeLanguage: true,
    });
  };

  const role = sessionStorage.getItem("OMNI2_ROLE");

  return (
    <div className="appbar">
      <div>
        <div className="navbar-header-left">
          {/*<div className="toggle">*/}
          {/*{*/}
          {/*props.auth*/}
          {/*? <span className="hamburger" onClick={props.toggle}>☰</span>*/}
          {/*: null*/}
          {/*}*/}
          {/*<span className="disabled hamburger" onClick={props.toggle}>☰</span>*/}
          {/*&nbsp;*/}
          {/*</div>*/}
          <div className="logo">
            {/*{t(`omni2`)}*/}
            {/*<img className="logo1" src={LogoImage} width="100px"/>*/}
            {/*<img className="logo2" src={LoganImage} width="100px"/>*/}
            {/*<img id="irespLogo" className="logo1" src={IrespLogo} width="200px"/>*/}
            <span id="irespLogo">{Configs.APP_TYPE === 1 ? "OAC" : "OAC"}</span>

            {localStorage.getItem(`${role}_OAH_CENTRE_NAME`) ? (
              <span className="cc-name">
                {sessionStorage.getItem(`${role}_token`)
                  ? "[ " +
                    localStorage.getItem(`${role}_OAH_CENTRE_NAME`) +
                    " ]"
                  : undefined}
              </span>
            ) : undefined}
          </div>
          {/*{*/}
          {/*props.auth*/}
          {/*? <span className="date2">{currentDate}</span>*/}
          {/*: null*/}
          {/*}*/}
        </div>

        <div className="navbar-header-right">
          {/*<div className="ib select">*/}
          {/*<img className="language" src={LanguageImage}/>*/}
          {/*<EspDropdown*/}
          {/*optionsTranslation={true}*/}
          {/*options={langOptions}*/}
          {/*// value={{value: props.selectedLanguage.value, label: t(`${props.selectedLanguage.label}`)}}*/}
          {/*onChange={props.changeLanguage}*/}
          {/*placeholder="Select Language"*/}
          {/*/>*/}
          {/*</div>*/}
          {renderDisconnect()}
          {sessionStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin &&
          sessionStorage.getItem(`${role}_token`)
            ? renderAlertImage()
            : null}
          {/*{localStorage.getItem("OMNI2_ROLE") === ROLES.OspMgr ? renderAlertImage() : null}*/}
          {/*{localStorage.getItem("OMNI2_ROLE") === ROLES.CcAdmin ? renderInbox() : null}*/}
          {/*{localStorage.getItem("OMNI2_ROLE") === ROLES.NurseAdmin ? renderInbox() : null}*/}
          {props.auth ? <UserDropdown {...props} /> : null}
          {/*{*/}
          {/*!props.auth*/}
          {/*? <span className="date">{currentDate}</span>*/}
          {/*: null*/}
          {/*}*/}
        </div>
      </div>
      {state.isOpenChangeLanguageEnterPassword ? (
        <form onSubmit={handleEnterPasswordConfirm}>
          <IrespModal
            title={ChangeLanguage.Title1}
            clazz="change-language-modal"
            contents={[
              <span>{ChangeLanguage.Content1}</span>,
              <input
                placeholder={ChangeLanguage.Password}
                type="password"
                onChange={(e) =>
                  setState({ ...state, devPassword: e.target.value })
                }
              />,
              state.devPasswordError ? (
                <div className="error">{state.devPasswordError}</div>
              ) : undefined,
            ]}
            buttons={[
              <button
                type="button"
                onClick={handleEnterPasswordCancel}
              >{`Cancel`}</button>,
              <button
                autoFocus={true}
                type="submit"
                onClick={handleEnterPasswordConfirm}
              >{`Confirm`}</button>,
            ]}
          />
        </form>
      ) : undefined}
      {state.isOpenChangeLanguage ? (
        <form onSubmit={handleChangeConfirm}>
          <IrespModal
            title={ChangeLanguage.Title2}
            clazz="change-language-modal"
            contents={[
              <span>{ChangeLanguage.Content2}</span>,
              <IrespDropdown
                value={state.priorityLanguage}
                options={[
                  <option value="en">{ChangeLanguage.English}</option>,
                  <option value="ja">{ChangeLanguage.Japanese}</option>,
                ]}
                handleSelect={(val) =>
                  setState({ ...state, priorityLanguage: val })
                }
              />,
              // state.devLanguageError
              //     ? <div className="error">{state.devLanguageError}</div>
              //     : undefined
            ]}
            buttons={[
              <button
                type="button"
                onClick={handleChangeCancel}
              >{`Cancel`}</button>,
              <button
                autoFocus={true}
                type="submit"
                onClick={handleChangeConfirm}
              >{`Confirm`}</button>,
            ]}
          />
        </form>
      ) : undefined}
    </div>
  );
}
