import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./EspModal.css";
import { messageWrapper as t } from "../../utils/message-wrapper";
import moment from "moment";
import _ from "lodash";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./customRcTimepickerCss.css";

const name = "components.modal";

export function EspModal(props) {
  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 0,
      y: 0,
    },
    controlledPosition: {
      x: -400,
      y: 200,
    },
    openPanel: {},
  });

  const limitRange =
    props.selectedDate &&
    moment(props.selectedDate).format("YYYYMMDD") ===
      moment().format("YYYYMMDD");

  useEffect(() => {
    setState({
      ...state,
      from: moment().hour(0).minute(0),
      to: limitRange
        ? moment().hour(moment().hours()).minute(moment().minute())
        : moment().hour(23).minute(59),
    });
    // return () => {
    //     setState({
    //         ...state,
    //         from: moment().hour(0).minute(0),
    //         to: limitRange
    //             ? moment().hour(moment().hours()).minute(moment().minute())
    //             : moment().hour(23).minute(59),
    //     })
    // }
  }, [props.isOpen]);

  const onStart = () => {
    setState({ ...state, activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    setState({ ...state, activeDrags: --state.activeDrags });
  };

  const handleDrag = (e, ui) => {
    const { x, y } = state.deltaPosition;
    setState({
      ...state,
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      },
    });
  };

  const handleConfirm = () => {
    const sendBack = {};
    if (props.inputs) {
      props.inputs.forEach((p) => {
        sendBack[p.toLowerCase()] = state[p.toLowerCase()];
      });
      props.handleConfirm(sendBack);
      return;
    }
    props.handleConfirm();
  };

  const handleChange = (value, input) => {
    let newState = { ...state };
    // set
    if (input === "from" && value.diff(state.to, "seconds") < 0) {
      newState = { ...state, [input]: value };
    } else if (input === "to" && value.diff(state.from, "seconds") > 0) {
      newState = { ...state, [input]: value };
    }

    // close
    if (
      moment(value).minutes() !== moment(state[input]).minutes() &&
      input === "from" &&
      value.diff(state.to, "seconds") < 0 &&
      input === "to" &&
      value.diff(state.from, "seconds") > 0
    ) {
      setState({
        ...newState,
        openPanel: { ...state.openPanel, [input]: false },
      });
    } else {
      setState({ ...newState });
    }
  };

  const ignoreHours = () => {
    const currentHour = moment().hours();
    return _.range(currentHour + 1, 24);
  };

  const ignoreMinutes = (selected) => {
    const currentHour = moment().hours();

    // don't limit minute
    if (moment(selected).hours() < currentHour) return _.range(60, 60);

    // limit minute
    const currentMin = moment().minutes();

    return _.range(currentMin, 60);
  };

  if (!props.isOpen) {
    return <div />;
  }

  return (
    <div className={"esp-modal" + " " + props.clazz}>
      {/*<Draggable*/}
      {/*position={{x: state.deltaPosition.x, y: state.deltaPosition.y}}*/}
      {/*onStart={onStart}*/}
      {/*onDrag={handleDrag}*/}
      {/*onStop={onStop}>*/}
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="forClick">
              {props.title}
            </h5>
          </div>
          <div className="modal-body">
            <p className="message">{props.content}</p>
            {props.content2 ? <p>{props.content2}</p> : ""}
            {props.content3 ? <p>{props.content3}</p> : ""}
            {props.value ? <p className="value">{props.value}</p> : null}
            {props.inputs
              ? props.inputs.map((input, idx) => {
                  return (
                    <div key={idx} className="group">
                      <div className="inline">
                        <div className="symbol">*</div>
                        <div className="label">{t(`${input}`)}:</div>
                      </div>
                      <div className="inline">
                        <TimePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          open={
                            state.openPanel &&
                            state.openPanel[input.toLowerCase()]
                          }
                          format={"HH:mm"}
                          showSecond={false}
                          allowEmpty={false}
                          inputReadOnly={true}
                          disabledHours={limitRange ? ignoreHours : () => {}}
                          disabledMinutes={
                            limitRange
                              ? () => ignoreMinutes(state[input.toLowerCase()])
                              : () => {}
                          }
                          value={state[input.toLowerCase()]}
                          onOpen={() =>
                            setState({
                              ...state,
                              openPanel: {
                                ...state.openPanel,
                                [input.toLowerCase()]: true,
                              },
                            })
                          }
                          onClose={() =>
                            setState({
                              ...state,
                              openPanel: {
                                ...state.openPanel,
                                [input.toLowerCase()]: false,
                              },
                            })
                          }
                          onChange={(value) =>
                            handleChange(value, input.toLowerCase())
                          }
                        />
                        {/*<InputDatePicker*/}
                        {/*onChange={(value) => setState({...state, [input.toLowerCase()]: value})}*/}
                        {/*value={state[input.toLowerCase()]}/>*/}
                      </div>
                    </div>
                  );
                })
              : null}
            {props.error ? <p className="error">{props.error}</p> : ""}
            {props.loading ? (
              <img className="status-loading" src={props.LoadingImage} />
            ) : null}
          </div>
          {props.noFooter ? (
            ""
          ) : (
            <div className="modal-footer">
              {props.definedButtons ? (
                props.definedButtons.map((button, idx) => (
                  <button type="button" onClick={props.handlers[idx]}>
                    {button}
                  </button>
                ))
              ) : (
                <>
                  <button type="button" onClick={props.handleClose}>
                    {t(`Cancel`)}
                  </button>
                  <button type="button" onClick={handleConfirm}>
                    {t(`Confirm`)}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {/*</Draggable>*/}
    </div>
  );
}

EspModal.propTypes = {
  // title: PropTypes.element,
  // content: PropTypes.element,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  // footer: PropTypes.element,
};
