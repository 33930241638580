import { authProvider, dataProvider } from "../../connections/net";
import $ from "jquery";
import { Versions } from "../../constants/versions";
import { isOldVersion } from "../../utils/check-version";

export const TOGGLE_SIDEBAR = "app/action/TOGGLE_SIDEBAR";
export const AUDIO_ON_OFF = "app/action/AUDIO_ON_OFF";
export const PLAY_ALERT = "app/action/PLAY_ALERT";
export const LOADING = "app/action/LOADING";
export const RECONNECT = "app/action/RECONNECT";
export const DELETE_OLD_LIVE_TILE = "app/action/DELETE_OLD_LIVE_TILE";
export const SET_LIVE_TILE = "app/action/SET_LIVE_TILE";
export const SET_OVER_RETRY = "app/action/SET_OVER_RETRY";
export const HIDE_OVER_RETRY = "app/action/HIDE_OVER_RETRY";
export const GET_IMPORT_DATA = "app/action/GET_IMPORT_DATA";
export const SHOULD_LOGOUT = "app/action/SHOULD_LOGOUT";
export const SET_ALREADY_OPEN = "app/action/SET_ALREADY_OPEN";
export const SET_VERSION = "app/action/SET_VERSION";
export const CLICKED_LIVE_VIEW = "app/action/CLICKED_LIVE_VIEW";

export const appAction = {
  toggleSidebar: () => (dispatch) =>
    dispatch({ type: TOGGLE_SIDEBAR, payload: {} }),
  logout: () => async (dispatch) => {
    try {
      await authProvider.logout();
    } catch (e) {
      console.log("Error:", e.message);
    }
    window.location.href = "/login";
    // dispatch({});
  },
  showLogoutModal: () => async (dispatch) => {
    // await authProvider.logoutWithoutRequest();
    dispatch({ type: SHOULD_LOGOUT });
  },
  onOffAudio: () => (dispatch) => {
    dispatch({ type: AUDIO_ON_OFF });
  },
  playAlert: (play) => (dispatch) => {
    dispatch({ type: PLAY_ALERT, payload: play });
  },
  openLoading: () => (dispatch) => {
    dispatch({ type: LOADING, payload: true });
  },
  closeLoading: () => (dispatch) => {
    setTimeout(() => {
      dispatch({ type: LOADING, payload: false });
    }, 100);
  },
  reconnect: (isReconnect, isOnline) => (dispatch) => {
    if (isReconnect) {
      localStorage.setItem("OMNI2_DISCONNECTED", "true");
    } else if (isOnline) {
      localStorage.removeItem("OMNI2_DISCONNECTED");
      localStorage.removeItem("OMNI2_HIDE_OVER_RETRY");
      $(".retry-modal .modal-footer").css("display", "auto");
      dispatch({ type: HIDE_OVER_RETRY, payload: false });
    }
    dispatch({ type: RECONNECT, payload: isReconnect });
  },
  deleteOldLiveTile: () => (dispatch) => {
    dispatch({ type: DELETE_OLD_LIVE_TILE, payload: true });
  },
  setLiveTile: (tile) => (dispatch) => {
    localStorage.setItem("OMNI2_TILES_OPENING", "true");
    dispatch({ type: SET_LIVE_TILE, payload: tile });
  },
  setOverRetry: (overRetry) => (dispatch) => {
    if (!overRetry) {
      localStorage.removeItem("OMNI2_OVER_RETRY");
    } else {
      localStorage.setItem("OMNI2_OVER_RETRY", "true");
    }
    dispatch({ type: SET_OVER_RETRY, payload: overRetry });
  },
  setHideOverRetry: (hide) => (dispatch) => {
    if (hide) {
      $(".retry-modal .modal-footer").css("display", "none");
      localStorage.setItem("OMNI2_HIDE_OVER_RETRY", "true");
    } else {
      $(".retry-modal .modal-footer").css("display", "auto");
      localStorage.removeItem("OMNI2_HIDE_OVER_RETRY");
    }
    dispatch({ type: HIDE_OVER_RETRY, payload: hide });
  },
  getCsvConfig: () => async (dispatch) => {
    const res = await dataProvider.getCsvConfig();
    const { status_code, center, deptThreshold, mat } = res;
    if (
      status_code !== 0 ||
      center.length < 1 ||
      center[0].name === "---" ||
      (mat.length === 0 && deptThreshold.length === 0)
    ) {
      dispatch({ type: GET_IMPORT_DATA, payload: { isSaved: false } });
      return;
    }
    dispatch({ type: GET_IMPORT_DATA, payload: { isSaved: true } });
  },
  setAlreadyOpen: (role) => async (dispatch) => {
    dispatch({ type: SET_ALREADY_OPEN, payload: role });
  },
  getVersions: () => async (dispatch) => {
    console.log("getVersions ...");
    try {
      const { web_version } = await dataProvider.getVersions();
      console.log(web_version);
      if (web_version) {
        const reloadToNewVersion = !isOldVersion(
          Versions.iresp_build_internal,
          web_version
        );
        localStorage.removeItem("OMNI2_DISCONNECTED");
      } else {
        localStorage.setItem("OMNI2_DISCONNECTED", "true");
      }
    } catch (e) {
      localStorage.setItem("OMNI2_DISCONNECTED", "true");
    }
  },
  pendingReloadToNewVersion: () => async (dispatch) => {
    dispatch({ type: SET_VERSION, payload: false });
  },
  clickedLiveView: () => async (dispatch) => {
    dispatch({ type: CLICKED_LIVE_VIEW, payload: true });
  },
};
