import { LiveTile } from "./LiveTile";
import React, { useEffect } from "react";
import { MatType } from "../../../constants/mat-type";
import { changeOverflow } from "../../../utils/width";
import NoSleep from "nosleep.js";

export function TypeWrapper(props) {
  let timer;

  useEffect(() => {
    // props.openLoading();
    changeOverflow("auto");

    // // no sleep
    // if (window.navigator.platform.toLowerCase().indexOf("win") === -1
    //     && window.navigator.platform.toLowerCase().indexOf("macintel") === -1) {
    //     const noSleep = new NoSleep();
    //     noSleep.enable();
    // }

    // polling
    // const pollingFunc = () => {
    //     props.pollingData();
    //     props.closeLoading();
    // };
    //
    // pollingFunc();
    // timer = setInterval(pollingFunc, 4000);
    //
    // return () => {
    //     clearTimeout(timer);
    // }
  }, []);

  if (!props.clickedLiveView) {
    props.history.push("/cc-admin-dashboard");
    return <div />;
  }

  if (!props.versions || true) {
    return <LiveTile {...props} />;
  }
}
